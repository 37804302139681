export default {
  'pages.creditReview.creditRecordManager.creditID': 'Credit ID',
  'pages.creditReview.creditRecordManager.IDNO': 'Id No.',
  'pages.creditReview.creditRecordManager.creditLimit': 'credit Limit',
  'pages.creditReview.creditRecordManager.productType': 'Product Type',
  'pages.creditReview.creditRecordManager.interestRateOne': 'Interest Rate1',
  'pages.creditReview.creditRecordManager.interestRateTwo': 'Interest Rate2',
  'pages.creditReview.creditRecordManager.creditStatus': 'Credit Status',
  'pages.creditReview.creditRecordManager.userChannels': 'User Channels',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditQuantity': 'Review Quantity',
  'pages.creditReview.FirstReview.manualAuditStatistics.onAuditQuantity': 'Under Review Quantity',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditRejectedQuantity':
    'Review Rejection Quantity',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditPassQuantity': 'Review Pass Quantity',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditSupplementsQuantity':
    'Review Supplements Quantity',
  'pages.creditReview.FirstReview.manualAuditStatistics.closeNum': 'Close Num',
  'pages.creditReview.FirstReview.manualAuditStatistics.rejectRate': 'Reject Rate',
  'pages.creditReview.FirstReview.manualAuditStatistics.replacementRate': 'Suppelment Rate',
  'pages.creditReview.FirstReview.manualAuditStatistics.passRate': 'Approve Rate',
  'pages.creditReview.FirstReview.manualAuditStatistics.approvalSuccessRate': 'Under Review Rate',
  'pages.creditReview.FirstReview.manualAuditStatistics.closeRate': 'Close Rate',
  'pages.creditReview.FirstReview.manualAuditStatistics.export': 'Export',
  'pages.creditReview.FirstReview.auditReview.reviewStatus': 'Review Status',
  'pages.creditReview.FirstReview.auditReview.enterAuditTime': 'Enter Review Time',
  'pages.creditReview.FirstReview.auditReview.release': 'Release',
  'pages.creditReview.FirstReview.auditReview.continueReview': 'Continue Review',
  'pages.creditReview.FirstReview.auditReview.review': 'Review',
  'pages.creditReview.FirstReview.auditedReview.creditSteps': 'Credit Steps',
  'pages.creditReview.FirstReview.auditedRevie.auditConclusion': 'Review Decision',
  'pages.creditReview.FirstReview.auditedRevie.auditDuration': 'Review Duration',
  'pages.creditReview.creditRecordManager.CID': 'Customer Id',
  'page.creditReview.creditRecordManager.userName': 'User Name',
  'page.creditReview.creditRecordManager.mobile': 'Mobile No',
  'pages.creditReview.creditRecordManager.credentialType': 'Id Type',
  'page.creditReview.creditRecordManager.statusUpdateTime': 'Status Update Time',
  'page.creditReview.creditRecordManager.applyTime': 'Apply Time',
  'pages.creditReview.creditRecordManager.operatorName': 'Reviewer Name',
  'page.creditReview.creditRecordManager.reviewer': 'Reviewer',
  'page.creditReview.creditRecordManager.conclusionManualReview': 'Manual Review Decision',
  'page.creditReview.creditRecordManager.loanId': 'Loan Id',
  'page.creditReview.creditRecordManager.amount': 'Loan Amount',
  'page.creditReview.creditRecordManager.loanStatus': 'Loan Status',
  'page.creditReview.creditRecordManager.finalApprovedTime': 'Final Approved Time',
  'page.creditReview.creditRecordManager.issueSuccessTime': 'Issue Success Time',
  'pages.creditReview.FirstReview.manualAuditStatistics.date': 'Date',
  'pages.creditReview.FirstReview.manualAuditStatistics.markingCompletedParts':
    'Tagging completed cases',
  'pages.creditReview.FirstReview.manualAuditStatistics.complianceRate': 'Tagging Rate',
  'pages.creditReview.creditRecordManager.action': 'Option',
  'common.read': 'Check',
  'pages.creditReview.FirstReview.auditedRevie.remark': 'Remark',
  'pages.creditReview.FirstReview.auditedRevie.customerCallTag': 'Customer Calling tag',
  'pages.creditReview.FirstReview.auditedRevie.contactOneDialsTag': 'Contact1 Calling tag',
  'pages.creditReview.FirstReview.auditedRevie.contactTwoDialsTag': 'Contact2 Calling tag',
  'pages.creditReview.FirstReview.auditedRevie.contactThreeDialsTag': 'Contact3 Calling tag',
  'pages.creditReview.creditRecordDetail.photoSimilarity': 'Bio Similarity',
  'pages.creditReview.FirstReview.auditedReview.reviewTime': 'Review time',
  'pages.creditReview.creditRecordDetail.realName': 'Username',
  'pages.creditReview.creditRecordDetail.credentialType': 'ID Type',
  'pages.creditReview.creditRecordDetail.cardNumber': 'ID Number',
  'pages.creditReview.creditRecordDetail.dateBirth': 'Date Of Birth',
  'pages.creditReview.creditRecordDetail.gender': 'Gender',
  'pages.creditReview.creditRecordDetail.age': 'Age',
  'pages.creditReview.creditRecordDetail.maritalStatus': 'Marital Status',
  'pages.creditReview.creditRecordDetail.purposeBorrowing': 'Purpose of borrowing',
  'pages.creditReview.creditRecordDetail.educationalLevel': 'Educational Level',
  'pages.creditReview.creditRecordDetail.numberCreditCards': 'Number of credit cards',
  'pages.creditReview.creditRecordDetail.area': 'Area',
  'pages.creditReview.creditRecordDetail.livingAddress': 'Living Address',
  'pages.creditReview.creditRecordDetail.email': 'Email',
  'pages.creditReview.creditRecordDetail.profession': 'Profession',
  'pages.creditReview.creditRecordDetail.salary': 'Monthly Income',
  'pages.creditReview.creditRecordDetail.payPeriod': 'Salary Cycle',
  'pages.creditReview.creditRecordDetail.salaryDay': 'Salary Day',
  'pages.creditReview.creditRecordDetail.contactName': 'Contact Name',
  'pages.creditReview.creditRecordDetail.contactPhone': 'Contact No',
  'pages.creditReview.creditRecordDetail.relationship': 'Contact Relationship',
  'pages.creditReview.creditRecordDetail.updateTime': 'Update Time',
  'pages.creditReview.creditRecordDetail.call': 'Dialing',
  'pages.creditReview.creditRecordDetail.addCallTag': 'Add calling Tag',
  'pages.creditReview.creditRecordDetail.contactCallTag': 'Calling Tag',
  'pages.creditReview.creditRecordDetail.firstRegisterTime': 'firstRegisteredTime',
  'pages.creditReview.creditRecordDetail.firstTransactionTime': 'firstTransactionTime',
  'pages.creditReview.creditRecordDetail.customerId': 'Customer Id',
  'pages.creditReview.creditRecordDetail.mobile': 'Mobile No',
  'pages.creditReview.creditRecordDetail.status': 'Status',
  'pages.creditReview.creditRecordDetail.createTime': 'Create Time',
  'pages.creditReview.creditRecordDetail.manualCalibration': 'ID Type-Manual Calibration',
  'pages.creditReview.creditRecordDetail.usedAmount': 'Used Amount',
  'pages.creditReview.creditRecordDetail.reviewingAmount': 'Under Review Amount',
  'pages.creditReview.creditRecordDetail.availableAmount': 'Remaining Available  Amount',
  'pages.creditReview.creditRecordDetail.interestRate': 'Product Daily Rate',
  'pages.creditReview.creditRecordDetail.effectiveTime': 'Credit Effective Time',
  'pages.creditReview.creditRecordDetail.ecpirationTime': 'Credit Expiry Time',
  'pages.creditReview.creditRecordDetail.name': 'Name',
  'pages.creditReview.creditRecordDetail.dateExpiry': 'Due Date',
  'pages.creditReview.creditRecordDetail.dateIssue': 'Issue Date',
  'pages.creditReview.creditRecordDetail.address': 'Address',
  'pages.creditReview.creditRecordDetail.eyesColor': 'Eye Color',
  'pages.creditReview.creditRecordDetail.issuingStateName': 'Issue Country',
  'pages.creditReview.creditRecordDetail.issuingStateCode': 'Issue Country Code',
  'pages.creditReview.creditRecordDetail.nationality': 'Nationality',
  'pages.creditReview.creditRecordDetail.result': 'Decision',
  'pages.creditReview.creditRecordDetail.callCustomer': 'Call Customer',
  'pages.creditReview.creditRecordDetail.personalInfo': 'Personal Info',
  'pages.creditReview.creditRecordDetail.employmentInfo': 'Employment Info',
  'pages.creditReview.creditRecordDetail.contactInfo': 'Contact Info',
  'pages.creditReview.creditRecordDetail.historyAccountInfo': 'History Account Info',
  'pages.creditReview.creditRecordDetail.productInfo': 'Product Info',
  'pages.creditReview.creditRecordDetail.addCustomeCallingTag': 'Add Customer Calling Tag',
  'pages.creditReview.creditRecordDetail.customerCallingTag': 'Customer Calling Tag',
  'menu.creditReview.FirstReview.auditedReview.manualCalibration': 'Manual Calibration',
  'pages.creditReview.creditRecordDetail.addIDTag': 'Add Id Tag',
  'pages.creditReview.creditRecordDetail.IDTag': 'Id Tag',
  'pages.creditReview.creditRecordDetail.reason': 'Reason',
  'pages.creditReview.creditRecordDetail.IDAnalyzeResult': 'Id Analyze Result',
  'pages.creditReview.creditRecordDetail.loanHistory': 'Loan History',
  'pages.creditReview.creditRecordDetail.BioSimilarity': 'Bio Similarity',
  'pages.creditReview.creditRecordDetail.loanID': 'Loan Id',
  'pages.creditReview.creditRecordDetail.loanApplyTime': 'Loan Apply Time',
  'pages.creditReview.creditRecordDetail.loanDisbursementTime': 'Issue Success Time',
  'pages.creditReview.creditRecordDetail.loanStatus': 'Loan Status',
  'pages.creditReview.creditRecordDetail.walletAccount': 'Disbursement Account',
  'pages.creditReview.creditRecordDetail.walletChannelOne': 'Disbursement Channel1',
  'pages.creditReview.creditRecordDetail.walletChannelTwo': 'Disbursement Channel2',
  'pages.creditReview.creditRecordDetail.amount': 'Amount',
  'pages.creditReview.creditRecordDetail.customerID': 'Customer Id',
  'pages.creditReview.creditRecordDetail.similarityScore': 'Similarity Score',
  'pages.creditReview.creditRecordDetail.loanType': 'Loan Type',
  'pages.creditReview.creditRecordDetail.productName': 'Product Name',
  'pages.creditReview.creditRecordDetail.switchManualReason':
    'Reasons for switching to manual audit',
  'pages.creditReview.creditRecordDetail.rejectReason': 'Reject Reason',
  'pages.creditReview.creditRecordDetail.isWhiteListCustomer': 'Is WhiteList Customer',
  'pages.creditReview.creditRecordDetail.creditApplyStatus': 'Credit Application Status',
  'pages.creditReview.creditRecordDetail.creditAppStep': 'Credit Approval Stage',
  'pages.creditReview.creditRecordDetail.reviewerId': 'Reviewer Id',
  'pages.creditReview.creditRecordDetail.startReviewTime': 'Manual Review Initiation Time',
  'pages.creditReview.creditRecordDetail.reviewResultTime': 'Manual Review Decision Time',
  'pages.creditReview.creditRecordDetail.reviewResult': 'Manual Review Decision',
  'pages.creditReview.creditRecordDetail.idTagReason': 'Id tag Reason',
  'pages.creditReview.creditRecordDetail.customerTagReason': 'Customer tag Reason',
  'pages.creditReview.creditRecordDetail.contactOneTagReason': 'Contact1 tag Reason',
  'pages.creditReview.creditRecordDetail.contactTwoTagReason': 'Contact2 tag Reason',
  'pages.creditReview.creditRecordDetail.contactThreeTagReason': 'Contact3 tag Reason',
  'pages.creditReview.creditRecordDetail.creditApplyTime': 'Credit Application Time',
  'pages.creditReview.creditRecordDetail.tagContent': 'tag Content',
  'pages.creditReview.creditRecordDetail.tagTime': 'tag Time',
  'pages.creditReview.creditRecordDetail.callName': 'Call Name',
  'pages.creditReview.creditRecordDetail.callNumber': 'Call Number',
  'pages.creditReview.creditRecordDetail.Relationship': 'Relationship',
  'pages.creditReview.creditRecordDetail.callResult': 'Call Result',
  'pages.creditReview.creditRecordDetail.beginCallTime': 'Call Time',
  'pages.creditReview.creditRecordDetail.objectName': 'Object Name',
  'pages.creditReview.creditRecordDetail.tagType': 'Type',
  'pages.creditReview.creditRecordDetail.tagReason': 'tag Reason',
  'pages.creditReview.creditRecordDetail.btn.pass': 'Approve',
  'pages.creditReview.creditRecordDetail.btn.reject': 'Reject',
  'pages.creditReview.creditRecordDetail.btn.tagCompleted': 'tag Completed',
  'pages.creditReview.creditRecordDetail.comments': 'comments',
  'pages.userManage.userFeedback.feedbackTime': 'feedback Time',
  'pages.userManage.userFeedback.feedbackImage': 'feedback Image',
  'pages.userManage.userFeedback.feedbackContent': 'feedback Content',
  'pages.creditReview.FirstReview.auditReview.lockReview':
    'The current case will be locked by you, please review as soon as possible!',
  'pages.creditReview.FirstReview.auditReview.releaseLockCase':
    'Are you sure to release the currently locked case?',
  'pages.creditReview.creditRecordDetail.addTagBtnMessage': 'Add successfully',
  'pages.creditReview.creditRecordDetail.detailReason': 'Detail Reason',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.by': 'Function caller',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.message': 'Info',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.surname': 'Last Name',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.middleName': 'Middle Name',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.givenName': 'First Name',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.province': 'Province',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.city': 'City',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.homeAddress': 'living address',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.service': 'Service Provider',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.reuslt': 'Result',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.returnMessage': 'Return Message',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.documentNumber': 'Document Number',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.issuerAuthority': 'Issuing Institution',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.documentType': 'Document Category Code',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.birthPlace': 'Place of Birth',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.issuingAuthority': 'Issuing Authority',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.issueDateParsed': 'Issuing Date',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.weight': 'Weight',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.restrictions':
    "Driver's License Restriction Code",
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.agencyCode': 'Agency Code',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.bloodType': 'Blood Type',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.conditions': 'Conditions',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.height': 'Height',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.occupation': 'Occupation',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.precinctNo': 'Voting District',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.civilStatus': 'Citizenship Status',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.chairman': 'Chairman',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.postCode': 'postal code',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.countryCode': 'Country Code',
  'pages.creditReview.creditRecordDetail.periodInterestRate': 'period Interest Rate',
  'pages.creditReview.creditRecordManager.interestRate': 'Interest Rate',
  'common.detail': 'go to detail',
  'pages.collection.my.log.caseId': 'Case Id',
  'pages.collection.my.log.classification': 'Classification',
  'pages.collection.my.log.comment': 'note',
  'pages.collection.my.log.customerId': 'Customer Id',
  'pages.collection.my.log.description': 'Description',
  'pages.collection.my.log.level': 'Level',
  'pages.collection.my.log.name': 'TagName',
  'pages.collection.my.log.parentId': 'Parent Id',
  'pages.collection.my.log.status': 'Status',
  'pages.collection.my.log.type': 'Type',
  'pages.collection.my.log.updateId': 'Update Id',
  'pages.collection.my.log.updateName': 'Update Name',
  'pages.collection.my.logTime': 'updated Log Time',
  'pages.collection.my.mobiles.backupMobile': 'Backup Mobile No.',
  'pages.collection.my.mobiles.caseType': 'Case Type',
  'pages.collection.my.mobiles.collectionlD': 'collectionlD',
  'pages.collection.my.mobiles.email': 'Email',
  'pages.collection.my.mobiles.level': 'Collection Level',
  'pages.collection.my.mobiles.mobile': 'Mobile No',
  'pages.collection.my.mobiles.name': 'User Name',
  'pages.collection.my.mobiles.overdueDays': 'Over due Days',
  'pages.collection.my.multHeaderInfo': 'Multi Platform Info',
  'pages.collection.my.openNewTab': 'open in new tab',
  'pages.collection.my.phoneNumber': 'phone number',
  'pages.collection.my.post': 'email',
  'pages.collection.my.ptp': 'Ptp',
  'pages.collection.my.reviewerId': 'Collector Id',
  'pages.collection.my.riskGrade': 'risk level',
  'pages.collection.my.searchDate': 'Search Date',
  'pages.collection.my.sendsms': 'Send SMS',
  'pages.collection.my.sevenDayMultHeaderNum': 'multi-platform No. within 7 days',
  'pages.collection.my.specialDivision.tip':
    'You must either enter a collector id or select a collector',
  'pages.collection.my.status': 'status',
  'pages.collection.my.teamLeaderName': 'Team Leader Name',
  'pages.collection.my.template': 'Template',
  'pages.collection.my.thirdpartyName': 'Thirdparty Name',
  'pages.collection.my.toDetail': 'View case details in batches',
  'pages.collection.my.trigger': 'Trigger',
  'pages.collection.my.userId': 'User Id',
  'pages.collection.my.username': 'Username',
  'pages.collection.my.userOverdueDays': 'User Overdue Days',
  'pages.collection.my.caseId': 'Case Id',
  'pages.collection.my.clearedNumber': 'paidoff loan periods No.',
  'pages.collection.my.collector': 'Collector',
  'pages.collection.my.contactMobiles': 'Contact Mobiles',
  'pages.collection.my.currentPrincipalToBeTepaid': 'Current Principal To Be Repaid',
  'pages.collection.my.customerType': 'Customer Type',
  'pages.collection.my.detail.accountHolderName': 'Account Holder Name',
  'pages.collection.my.detail.firstTransactionTime': 'firstTransactionTime',
  'pages.collection.my.detail.lastCreditTime': 'last Credit Time',
  'pages.collection.my.detail.lastCreditPassTime': 'last Credit PassTime',
  'pages.collection.my.detail.address': 'KTP address',
  'pages.collection.my.detail.adminName': 'Admin Name',
  'pages.collection.my.detail.adminNamed': 'Admin Named',
  'pages.collection.my.detail.amount': 'Loan Amount',
  'pages.collection.my.detail.amountCurrentlyPending': 'Current amount to be repaid',
  'pages.collection.my.detail.applicationId': 'Order ID',
  'pages.collection.my.detail.applicationID': 'Application ID',
  'pages.collection.my.detail.applicationLog': 'Application Log',
  'pages.collection.my.detail.applicationLogd': 'Application Logd',
  'pages.collection.my.detail.applicationStatus': 'Application Status',
  'pages.collection.my.detail.applyTime': 'Apply Time',
  'pages.collection.my.detail.appMobileInfo': 'App Mobile Info',
  'pages.collection.my.detail.appointmentDate': 'Commitment Repayment Date',
  'pages.collection.my.detail.appointmentDated': 'Appointment Dated',
  'pages.collection.my.detail.arrivedAmount': 'Amount received',
  'pages.collection.my.detail.backupMobile': 'Backup Mobile No.',
  'pages.collection.my.detail.bankBranch': 'Bank Branch',
  'pages.collection.my.detail.bankcardInfo': 'Bankcard Info',
  'pages.collection.my.detail.bankCode': 'Bank',
  'pages.collection.my.detail.birthday': 'Date Of Birth',
  'pages.collection.my.detail.calculateType': 'Types of Waivers',
  'pages.collection.my.detail.calculateType.all': 'Full Settlement',
  'pages.collection.my.detail.calculateType.part': 'Late Payment',
  'pages.collection.my.detail.callMobile': 'CallMobile',
  'pages.collection.my.detail.callName': 'CallName',
  'pages.collection.my.detail.callRelation': 'CallRelation',
  'pages.collection.my.detail.cardNumber': 'Card Number',
  'pages.collection.my.detail.channel': 'Channel',
  'pages.collection.my.detail.childrenNumber': 'Children Number',
  'pages.collection.my.detail.city': 'City',
  'pages.collection.my.detail.classification': 'Classification',
  'pages.collection.my.detail.clearedAmount': 'amount of settlement',
  'pages.collection.my.detail.collectionLevel': 'Collection Level',
  'pages.collection.my.detail.collectionMethod': 'Collection Method',
  'pages.collection.my.detail.collectionTask': 'Collection Task',
  'pages.collection.my.detail.collectionTaskd': 'Collection Taskd',
  'pages.collection.my.detail.collectorName': 'Collector Name',
  'pages.collection.my.detail.comment': 'Comment',
  'pages.collection.my.detail.comments': 'notes',
  'pages.collection.my.detail.commentsd': 'Commentsd',
  'pages.collection.my.detail.companyAddress': 'Company Address',
  'pages.collection.my.detail.companyArea': 'Company Area',
  'pages.collection.my.detail.companyCity': 'Company City',
  'pages.collection.my.detail.companyDistrict': 'Company District',
  'pages.collection.my.detail.companyName': 'Company Name',
  'pages.collection.my.detail.companyPhone': 'Company Phone',
  'pages.collection.my.detail.companyProvince': 'Company Province',
  'pages.collection.my.detail.complaintsContent': 'Complaints Content',
  'pages.collection.my.detail.complaintsInfo': 'Complaints Info',
  'pages.collection.my.detail.complaintsNumber': 'ComplaintsNumber',
  'pages.collection.my.detail.conclusion': 'Conclusion',
  'pages.collection.my.detail.contactInfo': 'Contact Info',
  'pages.collection.my.detail.couponAmount': 'Coupon Amount',
  'pages.collection.my.detail.couponCode': 'Coupon Code',
  'pages.collection.my.detail.couponEndTime': 'Coupon expiry Time',
  'pages.collection.my.detail.couponInterestThan': 'Coupon interest than',
  'pages.collection.my.detail.couponStartTime': 'Coupon Start Time',
  'pages.collection.my.detail.couponStatus': 'Coupon Status',
  'pages.collection.my.detail.couponStructure': 'Coupon Structure',
  'pages.collection.my.detail.couponType': 'Coupon Type',
  'pages.collection.my.detail.createTime': 'Create Time',
  'pages.collection.my.detail.createTimed': 'Create Time',
  'pages.collection.my.detail.credentialType': 'ID Type',
  'pages.collection.my.detail.creditQuality': 'Credit Quality',
  'pages.collection.my.detail.currentBreakPromiseNumber': 'CurrentBreakPromiseNumber',
  'pages.collection.my.detail.currentPeriod': 'Current Period',
  'pages.collection.my.detail.customerId': 'Customer ID',
  'pages.collection.my.detail.customerID': 'user ID',
  'pages.collection.my.detail.customerMobile': 'user phone',
  'pages.collection.my.detail.customerVersionType': 'CustomerVersionType',
  'pages.collection.my.detail.defaultAccr': 'penalty interest',
  'pages.collection.my.detail.defaultFeeAccr': 'penalty interest',
  'pages.collection.my.detail.defaultFeePaid': 'Liquidated penalty interest',
  'pages.collection.my.detail.defaultPaid': 'Liquidated penalty interest',
  'pages.collection.my.detail.defaultRealPaid': 'penalty  Interest Real Paid',
  'pages.collection.my.detail.defaultReduction': 'penalty  Interest Waive Off',
  'pages.collection.my.detail.district': 'District',
  'pages.collection.my.detail.dueAmount': 'Due Amount',
  'pages.collection.my.detail.dueDate': 'Due Date',
  'pages.collection.my.detail.dueLeftAmount': 'penalty Interest to be repaid',
  'pages.collection.my.detail.dueTotalAmount': 'principal to be repaid',
  'pages.collection.my.detail.duration': 'Duration',
  'pages.collection.my.detail.educationalLevel': 'Educational Level',
  'pages.collection.my.detail.email': 'Email',
  'pages.collection.my.detail.employmentInfo': 'Employment Info',
  'pages.collection.my.detail.eraseAmount': 'Erase Amount',
  'pages.collection.my.detail.expireAmount': 'Overdue Amount',
  'pages.collection.my.detail.expireInterestAmount': 'Overdue Interest',
  'pages.collection.my.detail.expirePrincipleAmount': 'Overdue Principal',
  'pages.collection.my.detail.expirePunishmentAmount': 'Overdue penalty',
  'pages.collection.my.detail.facebookId': 'Facebook Id',
  'pages.collection.my.detail.gender': 'Gender',
  'pages.collection.my.detail.gracePeriodRate': 'Grace Period Rate',
  'pages.collection.my.detail.historyAccountInfo': 'History Account Info',
  'pages.collection.my.detail.historyApplications': 'History Applications',
  'pages.collection.my.detail.iatestComment': 'LatestComment',
  'pages.collection.my.detail.ibsAddress': 'Ibs Address',
  'pages.collection.my.detail.id': 'Id',
  'pages.collection.my.detail.iiveTime': 'Iive Time',
  'pages.collection.my.detail.iivingAddress': 'Living Address',
  'pages.collection.my.detail.interestAccr': 'Interest payable',
  'pages.collection.my.detail.interestAmount': 'Outstanding Interest',
  'pages.collection.my.detail.interestFreeStamps': 'InterestFreeStamps',
  'pages.collection.my.detail.interestPaid': 'Cleared Interest',
  'pages.collection.my.detail.interestRate': 'Interest Rate',
  'pages.collection.my.detail.interestRealPaid': 'Real Paid Interest',
  'pages.collection.my.detail.interestReduction': 'Interest Reduction',
  'pages.collection.my.detail.iPayId': 'lPay ID',
  'pages.collection.my.detail.ipayStatus': 'Lpay Status',
  'pages.collection.my.detail.isRepayCustomer': 'Whether to repay or not',
  'pages.collection.my.detail.issueAmount': 'Issue Amount',
  'pages.collection.my.detail.issueMethod': 'Issue Method',
  'pages.collection.my.detail.issueStatus': 'Issue Status',
  'pages.collection.my.detail.issueSuccessCount': 'Number of borrowings',
  'pages.collection.my.detail.isWhiteListCustomer': 'White List Customer',
  'pages.collection.my.detail.lastPunishmentTime': 'Penalty interest update time',
  'pages.collection.my.detail.level': 'Collection level',
  'pages.collection.my.detail.loanAppId': 'Loan App Id',
  'pages.collection.my.detail.loanCoupon': 'Loan Coupon',
  'pages.collection.my.detail.loanId': 'Loan Id',
  'pages.collection.my.detail.loanInfo': 'Loan Info',
  'pages.collection.my.detail.LoanInfo(CURRENT)': 'Loan Info(CURRENT)',
  'pages.collection.my.detail.loanIssueHistory': 'Loan Issue History',
  'pages.collection.my.detail.loanIssueId': 'Loan Issue ID',
  'pages.collection.my.detail.LoanStatus': 'Margin Status',
  'pages.collection.my.detail.LoanSubType': 'Loan SubType',
  'pages.collection.my.detail.loanType': 'Loan Type',
  'pages.collection.my.detail.logId': 'Log ID',
  'pages.collection.my.detail.logIdd': 'Log Idd',
  'pages.collection.my.detail.marginOfCredit': 'Margin Of Credit',
  'pages.collection.my.detail.maritalStatus': 'Marital Status',
  'pages.collection.my.detail.mobile': 'Mobile No',
  'pages.collection.my.detail.motherMiddleName': "mother's last name",
  'pages.collection.my.detail.name': 'Contact Name',
  'pages.collection.my.detail.newCollectionLog': 'New Collection Log',
  'pages.collection.my.detail.oldCollectionLog': 'Old Collection Log',
  'pages.collection.my.detail.outCallsLog': 'out-call log',
  'pages.collection.my.detail.operator': 'Operator',
  'pages.collection.my.detail.operatorComment': 'notes',
  'pages.collection.my.detail.operatord': 'Operatord',
  'pages.collection.my.detail.operatorName': 'Operator Name',
  'pages.collection.my.detail.outstandingInterest': 'Interest not yet due',
  'pages.collection.my.detail.outstandingPrincipalAmount': 'Principal not yet due',
  'pages.collection.my.detail.overdueDays': 'Overdue Days',
  'pages.collection.my.detail.overdueFeeRate': 'Overdue Fee',
  'pages.collection.my.detail.overdueRate': 'OverdueReason',
  'pages.collection.my.detail.overdueReason': 'Paidoff Amount',
  'pages.collection.my.detail.paidOffTime': 'Paid Off Time',
  'pages.collection.my.detail.penaltyFactory': 'Penalty Factory',
  'pages.collection.my.detail.personalInfo': 'Personal Info',
  'pages.collection.my.detail.phone': 'Phone',
  'pages.collection.my.detail.phoneNumber': 'Mobile No',
  'pages.collection.my.detail.ppn': 'PPN',
  'pages.collection.my.detail.principalAccr': 'Principal repayable',
  'pages.collection.my.detail.principalPaid': 'Cleared Principal',
  'pages.collection.my.detail.principalRealPaid': 'Real Paid Principle',
  'pages.collection.my.detail.principalReduction': 'Principle Reduction',
  'pages.collection.my.detail.principleAmount': 'Principal to be repaid',
  'pages.collection.my.detail.productName': 'Product Name',
  'pages.collection.my.detail.profession': 'Profession',
  'pages.collection.my.detail.province': 'Province',
  'pages.collection.my.detail.punishmentAmount': 'penalty Interest to be repaid',
  'pages.collection.my.detail.quickSelectionOfCollectionCases':
    'Quick selection of collection cases',
  'pages.collection.my.detail.randomId': 'Random Id',
  'pages.collection.my.detail.reachedAmount': 'Amount received',
  'pages.collection.my.detail.realName': 'Real Name',
  'pages.collection.my.detail.reduceAmount': 'Reduction amount',
  'pages.collection.my.detail.reduceType': 'Reduction plan',
  'pages.collection.my.detail.relation': 'Relation',
  'pages.collection.my.detail.relationship': 'Relationship',
  'pages.collection.my.detail.RemissionInformation': 'Reduction Information',
  'pages.collection.my.detail.repaymentAmount': 'Repayment Amount',
  'pages.collection.my.detail.repaymentChannel': 'Repayment Channel',
  'pages.collection.my.detail.repaymentHistory': 'Repayment Settlement History',
  'pages.collection.my.detail.repaymentId': 'Repayment ID',
  'pages.collection.my.detail.repaymentMethod': 'Repayment Method',
  'pages.collection.my.detail.repaymentPlan': 'Repayment Plan',
  'pages.collection.my.tab.repayRecord': 'Repayment Record',
  'page.review.creditRecordManager.detail.loanHistory': 'LoanHistory',
  'pages.collection.my.detail.repaymentStatus': 'Repayment Status',
  'pages.collection.my.detail.reviewer': 'Reviewer',
  'pages.collection.my.detail.rolloverFeeAccr': 'Rollover Fee Repayable',
  'pages.collection.my.detail.rolloverFeePaid': 'Repaid Rollover Fee',
  'pages.collection.my.detail.rolloverLeftAmount': 'Outstanding Interest',
  'pages.collection.my.detail.rolloverPrincipalAccr': 'Rollover principal Repayable',
  'pages.collection.my.detail.rolloverPrincipalPaid': 'Repaid Rollover principal',
  'pages.collection.my.detail.rolloverPrincipalRate': 'Rollover ratio of principal repayable',
  'pages.collection.my.detail.rolloverTimes': 'Rollover Time',
  'pages.collection.my.detail.salary': 'Salary',
  'pages.collection.my.detail.salaryDay': 'Salary Day',
  'pages.collection.my.detail.serviceFeeAccr': 'Service Fee Repayable',
  'pages.collection.my.detail.serviceFeePaid': 'Service Fee Paid',
  'pages.collection.my.detail.SIMCARDMOBILE1': 'SIM CARD MOBILE1',
  'pages.collection.my.detail.SIMCARDMOBILE2': 'SIM CARD MOBILE2',
  'pages.collection.my.detail.status': 'Status',
  'pages.collection.my.detail.stepExecId': 'Execution ID',
  'pages.collection.my.detail.subStatus': 'Sub Status',
  'pages.collection.my.detail.taskId': 'Task Id',
  'pages.collection.my.detail.taskIdd': 'Task Idd',
  'pages.collection.my.detail.taskStatus': 'Task Status',
  'pages.collection.my.detail.taskStatusd': 'Task Statusd',
  'pages.collection.my.detail.taskType': 'Task Type',
  'pages.collection.my.detail.taskTyped': 'Task Typed',
  'pages.collection.my.detail.throttleId': 'Throttle Id',
  'pages.collection.my.detail.throttleReason': 'Reason',
  'pages.collection.my.detail.throttleType': 'Type',
  'pages.collection.my.detail.totalBreakPromiseNumber': 'Total Break Promise Number',
  'pages.collection.my.detail.totalPeriod': 'Total Period',
  'pages.collection.my.detail.updateTime': 'Update Time',
  'pages.collection.my.detail.userLevel': 'User Level',
  'pages.collection.my.detail.username': 'Username',
  'pages.collection.my.detail.userPhoneStatus': 'UserPhoneStatus',
  'pages.collection.my.detail.vatAccr': 'VAT Repayable',
  'pages.collection.my.detail.vatPaid': 'Repaid VAT',
  'pages.collection.my.detail.vatRealPaid': 'VAT Real Paid',
  'pages.collection.my.detail.vatReduction': 'VAT Reduction',
  'pages.collection.my.detail.verifyStatus': 'Verify Status',
  'pages.collection.my.detail.workEmail': 'Work Email',
  'pages.collection.my.detail.workStartFrom': 'Working hours',
  'pages.collection.my.distributedDays': 'Distributed Days',
  'pages.collection.my.distributionId': 'Distribution ID',
  'pages.collection.my.goToDetail': 'go to detail',
  'pages.collection.my.groupId': 'Group ID',
  'pages.collection.my.groupName': 'CollectionGroup Name',
  'pages.collection.my.lastLogCreateTime': 'Last updated log time',
  'pages.collection.my.level': 'Collection Level',
  'pages.collection.my.collectionTag': 'Collection tag',
  'pages.collection.my.lastRepaytime': 'last repayment time',
  'pages.collection.my.lastCollectime': 'last collection log time',
  'pages.collection.my.dividetime': 'case assignment time',
  'common.option': 'Operation',
  'pages.userManage.userList.userPhone': 'user phone',
  'pages.userManage.userList.userStatus': 'user status',
  'pages.userManage.userList.registerDate': 'Date of registration',
  'pages.userManage.userList.oneLevelChannel': 'Primary Channel',
  'pages.userManage.userList.secondaryChannels': 'Secondary Channel',
  'pages.userManage.userList.loanAmount': 'Loan Amount',
  'pages.userManage.userList.registerTime': 'Registration Time',
  'pages.userManage.userList.detail.personalInfo': 'personal info',
  'pages.userManage.userList.detail.workInfo': 'work info',
  'pages.userManage.userList.detail.contactsInfo': 'contact info',
  'pages.userManage.userList.detail.documentInfo': 'ID info',
  'pages.userManage.userList.detail.basicInfo': 'basic info',
  'pages.userManage.userList.detail.fillingCapitalInfo': 'filling info',
  'pages.userManage.userList.detail.loanPurpose': 'purpose of loan',
  'pages.userManage.userList.detail.creditCardsNum': 'Number of Credit Card',
  'pages.userManage.userList.detail.emailAddress': 'Personal email',
  'pages.userManage.userList.detail.residentialAreaProvince': 'Residential Address-Province',
  'pages.userManage.userList.detail.residentialAreaCity': 'Residential Address-City',
  'pages.userManage.userList.detail.residentialAreaDistrict': 'Residential Address-Barangay',
  'pages.userManage.userList.detail.residentialAreaDetailedAddress':
    'Residential Address-detailed address',
  'pages.userManage.userList.detail.workOccupation': 'Job Occupation',
  'pages.userManage.userList.detail.workIndustry': 'Job Industry',
  'pages.userManage.userList.detail.monthlyIncome': 'Monthly Income',
  'pages.userManage.userList.detail.monthlyPayday': 'Monthly Pay Day',
  'pages.userManage.userList.detail.contactRelationship': 'relationship',
  'pages.userManage.userList.detail.contactName': 'contact name',
  'pages.userManage.userList.detail.contactPhone': 'contact phone number',
  'pages.userManage.userList.detail.imageFront': 'ID image(front)',
  'pages.userManage.userList.detail.imageBack': 'ID image(back)',
  'pages.userManage.userList.detail.paperID': 'ID number',
  'pages.userManage.userList.detail.paperName': 'ID name',
  'pages.userManage.userList.detail.periodValidity': 'validity of ID',
  'pages.userManage.userList.detail.tripartiteFeedback': 'tripartite Feedback',
  'pages.userManage.userList.detail.identeConclusion': 'Identification conclusion',
  'pages.userManage.userList.detail.tongDunOCR': 'OCR',
  'pages.userManage.userList.detail.livePictures': 'Bio image',
  'pages.userManage.userList.detail.liveVideo': 'Bio video',
  'pages.userManage.userList.detail.limitInfo': 'limit info',
  'pages.userManage.userList.detail.totalLimit': 'Credit Line',
  'pages.userManage.userList.detail.onLoanLimit': 'amount in loan',
  'pages.userManage.userList.detail.availablLimit': 'Available credit',
  'pages.userManage.userList.detail.orderRecord': 'Order Record',
  'pages.userManage.userList.detail.creditRecords': 'credit record',
  'pages.userManage.userList.detail.withdrawalRecord': 'withdrawal record',
  'pages.userManage.userList.detail.repaymentPlan': 'repayment plan',
  'pages.userManage.userList.detail.paymentHistory': 'repayment record',
  'pages.userManage.userList.detail.creditType': 'credit type',
  'pages.userManage.userList.detail.limitChange': 'limit change',
  'pages.userManage.userList.detail.orderId': 'Order ID',
  'pages.userManage.userList.detail.creditTime': 'Credit Approval Time',
  'pages.userManage.userList.detail.performanceStatus': 'Fulfillment status',
  'pages.userManage.userList.detail.loanTime': 'loan time',
  'pages.userManage.userList.detail.repaymentPlanId': 'repayment plan ID',
  'pages.userManage.userList.detail.paymentDate': 'date of repayment',
  'pages.userManage.userList.detail.status': 'status',
  'pages.userManage.userList.detail.payableAmount': 'repayable amount',
  'pages.userManage.userList.detail.repaidAmount': 'repaid amount',
  'pages.userManage.userList.detail.payablePrincipal': 'repayable principal',
  'pages.userManage.userList.detail.repaidPrincipal': 'repaid principal',
  'pages.userManage.userList.detail.payableInterest': 'repayable interest',
  'pages.userManage.userList.detail.interestPaid': 'repaid interest',
  'pages.userManage.userList.detail.payableServiceFee': 'repayable service fee',
  'pages.userManage.userList.detail.paidServiceFee': 'repaid service fee',
  'pages.userManage.userList.detail.payableOverdueInterest': 'repayable overdue interest',
  'pages.userManage.userList.detail.overdueInterestRepayable': 'repaid overdue interest',
  'pages.userManage.userList.detail.dueOverduePenaltyInterest': 'repayable overdue penalty',
  'pages.userManage.userList.detail.overdueInterestRepaid': 'repaid overdue penalty',
  'pages.userManage.userList.detail.finalRepaymentDate': 'final Repayment Date',
  'pages.userManage.userList.detail.repaymentStatementID': 'Repayment Statement ID',
  'pages.userManage.userList.detail.repaymentSubChannels': 'Repayment SubChannel',
  'pages.userManage.userList.detail.repaymentCode': 'Repayment Code',
  'pages.userManage.userList.detail.repaymentTime': 'Repayment Time',
  'pages.userManage.userList.detail.repaymentPrincipal': 'Repayment Principal',
  'pages.userManage.userList.detail.repaymentInterest': 'Repayment Interest',
  'pages.userManage.userList.detail.repaymentServiceFee': 'Repayment Service Fee',
  'pages.userManage.userList.detail.lateRepaymentInterest': 'Repayment Overdue Interest',
  'pages.userManage.userList.detail.latePenaltyInterest': 'Repayment Overdue Penalty',
  'commom.close': 'Close',
  'pages.collection.my.detail.lastestRecordTag': 'last collection log tag',
  'pages.collection.my.detail.lastestRecordTime': 'last collection log time',
  'pages.collection.my.detail.lastestRepayAmount': 'last repayment amount',
  'pages.collection.my.detail.lastestRepayTime': 'last repayment time',
  'pages.loanManage.loanRecords.product': 'product',
  'pages.loanManage.loanRecords.feeRate': 'rate',
  'pages.loanManage.loanRecords.actualLoanAmount': 'Actual Loan Amount',
  'pages.loanManage.loanRecords.orderDueDate': 'Order Due Date',
  'pages.loanManage.loanRecords.totalInterestPayable': 'Total Payable Interest',
  'pages.loanManage.loanRecords.thirdTransactionID': 'Three-party transaction ID',
  'pages.loanManage.loanRecords.paymentSystemID': 'Payment system ID',
  'pages.loanManage.loanRecords.threeDisbursementTime':
    'the actual disbursement time provided by Three parties',
  'pages.loanManage.loanRecords.successfulTime': 'Successful Disbursement Time',
  'pages.loanManage.loanRecords.paymentChannels': 'Payment Channels',
  'pages.loanManage.loanRecords.paymentCardNumber': 'Card Number for Receiving Payments',
  'pages.loanManage.loanRecords.nameReceiveCard': 'Cardholder Name for Receiving Payments',
  'pages.loanManage.loanRecords.receiveCardBank': 'Account Number for Receiving Payments',
  'common.close': 'Close',
  'pages.system.privilege.user.employeeId': 'Employee ID',
  'pages.system.privilege.user.entityId': 'Entity ID',
  'pages.system.privilege.user.action': 'Option',
  'pages.system.privilege.user.action.CollectionUser.delete': 'Leave (delete the employee)',
  'pages.system.privilege.user.action.CollectionUser.delete.confirm': 'Confirm Deletion?',
  'pages.system.privilege.user.action.editPassword': 'Edit Password',
  'pages.system.privilege.user.action.managePermission': 'manage permission',
  'pages.system.privilege.user.action.modifyUserRole': 'modify user role',
  'pages.system.privilege.user.action.showUserRole': 'show user role',
  'pages.system.privilege.user.action.update': 'update',
  'pages.system.privilege.user.addPermissionForm.id': 'id',
  'pages.system.privilege.user.addPermissionForm.title': 'manage Permission',
  'pages.system.privilege.user.create': 'Create User',
  'pages.system.privilege.user.email': 'Email',
  'pages.system.privilege.user.expandRow.action': 'option',
  'pages.system.privilege.user.expandRow.action.delete': 'delete',
  'pages.system.privilege.user.expandRow.action.delete.confirm': 'confirm delete?',
  'pages.system.privilege.user.expandRow.id': 'id',
  'pages.system.privilege.user.expandRow.name': 'name',
  'pages.system.privilege.user.expandRow.remark': 'remark',
  'pages.system.privilege.user.expandRow.type': 'type',
  'pages.system.privilege.user.groupId': 'Group ID',
  'pages.system.privilege.user.id': 'ID',
  'pages.system.privilege.user.ModifyUserRoleForm.table.id': 'id',
  'pages.system.privilege.user.ModifyUserRoleForm.table.name': 'name',
  'pages.system.privilege.user.ModifyUserRoleForm.table.remark': 'remark',
  'pages.system.privilege.user.ModifyUserRoleForm.title': 'Modify User Role',
  'pages.system.privilege.user.phone': 'account',
  'pages.system.privilege.user.realName': 'RealName',
  'pages.system.privilege.user.status': 'Status',
  'pages.system.privilege.user.teamLeaderName': 'Team Leader Name',
  'pages.system.privilege.user.title': 'User Management',
  'pages.system.privilege.user.userForm.3cxCallerId': '3cx Caller ID',
  'pages.system.privilege.user.userForm.email': 'email',
  'pages.system.privilege.user.userForm.groupId': 'groupName',
  'pages.system.privilege.user.userForm.id': 'id',
  'pages.system.privilege.user.userForm.password': 'password',
  'pages.system.privilege.user.userForm.phone': 'phone',
  'pages.system.privilege.user.userForm.realName': 'real name',
  'pages.system.privilege.user.userForm.status': 'Status',
  'pages.system.privilege.user.userForm.title.create': 'create user',
  'pages.system.privilege.user.userForm.title.update': 'update user',
  'pages.system.privilege.user.UserRoleModal.table.action': 'action',
  'pages.system.privilege.user.UserRoleModal.table.action.delete': 'delete',
  'pages.system.privilege.user.UserRoleModal.table.action.delete.confirm': 'confirm delete?',
  'pages.system.privilege.user.UserRoleModal.table.id': 'id',
  'pages.system.privilege.user.UserRoleModal.table.name': 'name',
  'pages.system.privilege.user.UserRoleModal.table.remark': 'remark',
  'pages.system.privilege.user.UserRoleModal.title': 'user role info',
  'pages.system.privilege.group.expandedRow.adminId': 'Admin ID',
  'pages.system.privilege.group.expandedRow.adminStatus': 'Admin Status',
  'pages.system.privilege.group.expandedRow.fullName': 'Full Name',
  'pages.system.privilege.group.expandedRow.groupAdminUpdate': 'Group Admin Update',
  'pages.system.privilege.group.expandedRow.groupId': 'Group ID',
  'pages.system.privilege.group.expandedRow.levelWeight': 'Weight',
  'pages.system.privilege.group.expandedRow.manualAverageCase': 'manual equalization of cases',
  'pages.system.privilege.group.expandedRow.manualAverageCase.confirm':
    'Confirm manual equalization of cases?',
  'pages.system.privilege.group.expandedRow.manualResetCase':
    'The case for the release of the collector',
  'pages.system.privilege.group.expandedRow.manualResetCase.confirm':
    'Confirm manual release case?',
  'pages.system.privilege.group.expandedRow.mobile': 'Mobile',
  'pages.system.privilege.group.expandedRow.status': 'Status',
  'pages.system.privilege.group.expandedRow.updateTime': 'Update Time',
  'pages.system.privilege.group.expandedRow.weight': 'Divisional weight',
  'pages.collection.contactMobiles.caseType': 'Case Type',
  'pages.collection.contactMobiles.customerName': "Borrower's name",
  'pages.collection.contactMobiles.DPD': 'DPD',
  'pages.collection.contactMobiles.mobile': 'Emergency Contact No',
  'pages.collection.contactMobiles.name': 'Emergency Contact Name',
  'pages.collection.contactMobiles.relationship': 'Relationship',
  'pages.collection.contactMobiles.tag': 'Tag',
  'pages.collection.blacklist.comment': 'Comment',
  'pages.collection.blacklist.createBlacklist': 'Create Blacklist',
  'pages.collection.blacklist.createTime': 'CreateTime',
  'pages.collection.blacklist.customerId': 'CustomerId',
  'pages.collection.blacklist.editBlacklist': 'Edit Blacklist',
  'pages.collection.blacklist.fileType': 'File Type',
  'pages.collection.blacklist.mobile': 'Mobile',
  'pages.collection.blacklist.operation': 'Operate',
  'pages.collection.blacklist.status': 'Status',
  'pages.collection.blacklist.tip': 'Please select table row',
  'pages.collection.blacklist.updateTime': 'UpdateTime',
  'pages.creditReview.creditRecordDetail.approveReason': 'Approve Reason',
  'common.effectiveDate': 'EffectiveDate',
  'common.endDate': 'EndDate',
  'pages.creditReview.creditRecordDetail.btn.supplement': 'Supplement',
  'pages.creditReview.creditRecordDetail.supplementReason': 'Supplement Reason',
  'pages.collection.monitor.callAverageNum': 'Call Average Num',
  'pages.collection.monitor.callCaseNum': 'Call Case Num',
  'pages.collection.monitor.callCoverRate': 'Call Cover Rate',
  'pages.collection.monitor.callDimension': 'Call Dimension',
  'pages.collection.monitor.callNum': 'Call Num',
  'pages.collection.monitor.contactNum': 'Contact Num',
  'pages.collection.monitor.contactRate': 'Contact Rate',
  'pages.collection.monitor.count': 'Count',
  'pages.collection.monitor.date': 'Date',
  'pages.collection.monitor.finishDimension': 'Finish Dimension',
  'pages.collection.monitor.groupId': 'Group Id',
  'pages.collection.monitor.groupName': 'Group Name',
  'pages.collection.monitor.notUrgeCallAverageNum': 'Not Urge Call Average Num',
  'pages.collection.monitor.notUrgeCallCaseNum': 'Not Urge Call Case Num',
  'pages.collection.monitor.notUrgeCallCoverRate': 'Not Urge Call Cover Rate',
  'pages.collection.monitor.notUrgeCallNum': 'Not Urge Call Num',
  'pages.collection.monitor.notUrgeCount': 'Not Urge Count',
  'pages.collection.monitor.notUrgeRank': 'Not Urge Rank',
  'pages.collection.monitor.principalAssignAmount': 'Principal Assign Amount',
  'pages.collection.monitor.principalRank': 'Ranking of Principal Recover Amount',
  'pages.collection.monitor.principalRecallAmount': 'Principal Recover Amount',
  'pages.collection.monitor.principalRecallRate': 'Principal Recover Rate',
  'pages.collection.monitor.product': 'Product',
  'pages.collection.monitor.rank': 'Rank',
  'pages.collection.monitor.recoveryRate.day': 'Day',
  'pages.collection.monitor.recoveryRate.month': 'Month',
  'pages.collection.monitor.recoveryRate.week': 'Week',
  'pages.collection.monitor.reviewerId': 'Collector Id',
  'pages.collection.monitor.reviewerName': 'Collector Name',
  'pages.collection.monitor.totalAssignAmount': 'Total Assign Amount',
  'pages.collection.monitor.totalRank': 'Ranking of Total Recover Amount',
  'pages.collection.monitor.totalRecallAmount': 'Total Recover Amount',
  'pages.collection.monitor.totalRecallRate': 'Total Recover %',
  'pages.collection.monitor.urgeCallAverageNum': 'Urge Call Average Num',
  'pages.collection.monitor.urgeCallCaseNum': 'Urge Call Case Num',
  'pages.collection.monitor.urgeCallCoverRate': 'Urge Call Cover Rate',
  'pages.collection.monitor.urgeCallNum': 'Urge Call Num',
  'pages.collection.monitor.urgeCount': 'Urge Count',
  'pages.collection.monitor.urgeRank': 'Urge Rank',
  'pages.collection.specialDivision.assignBeforeCount': 'Assign Before Count',
  'pages.collection.specialDivision.assignLaterCount': 'Assign Later Count',
  'pages.collection.specialDivision.confirmDivision': 'Confirm Division',
  'pages.collection.specialDivision.confirmDivision.confirm': 'Confirm Division？',
  'pages.collection.specialDivision.groupId': 'Group Id',
  'pages.collection.specialDivision.groupName': 'Group Name',
  'pages.collection.specialDivision.leaveDivision': 'Leave Division',
  'pages.collection.specialDivision.manualDivision': 'Manual Division',
  'pages.collection.specialDivision.preview': 'Preview of division result',
  'pages.collection.specialDivision.resignationDivision': 'Resignation Division',
  'pages.collection.specialDivision.reviewer': 'Collector Id',
  'pages.collection.specialDivision.reviewerId': 'Collector Id',
  'pages.collection.specialDivision.reviewerName': 'Collector Name',
  'pages.collection.specialDivision.searchLabel': 'Select Group or Collector',
  'pages.collection.specialDivision.to': 'Assigned to',
  'pages.collection.specialDivision.trigger': 'Trigger',
  'pages.collection.specialDivision.warning': 'The assigned person status needs to be inactive',
  'pages.collection.divisionRecor.createTime': 'Distribution time',
  'pages.collection.divisionRecord.caseId': 'Case Id',
  'pages.collection.divisionRecord.customerId': 'CustomerId',
  'pages.collection.divisionRecord.customerType': 'Customer Type',
  'pages.collection.divisionRecord.id': 'Log Id',
  'pages.collection.divisionRecord.operatorId': 'Operator Id',
  'pages.collection.divisionRecord.operatorName': 'Operator Name',
  'pages.collection.divisionRecord.poolId': 'Pool Id',
  'pages.collection.divisionRecord.reviewerId': 'Collector Id',
  'pages.collection.divisionRecord.reviewerName': 'Collector Name',
  'pages.collection.divisionRecord.status': 'Status',
  'pages.collection.divisionRecord.teamLeaderName': 'Team Leader',
  'pages.collection.divisionRecord.topic': 'Topic',
  'common.querylimit.tip':
    'Only support querying all data in the past week or data of a single user',
  'pages.coolection.divisionRecord.topicEnum.absenceAssignCaseToReviewer': 'leave case',
  'pages.coolection.divisionRecord.topicEnum.dailyAssignCaseToReviewer': 'day division',
  'pages.coolection.divisionRecord.topicEnum.manualAssignCaseToReviewer': 'Manual division',
  'pages.coolection.divisionRecord.topicEnum.monthAssignCaseToReviewer': 'Monthly division',
  'pages.coolection.divisionRecord.topicEnum.resignationAssignCaseToReviewer':
    'Resignation Division',
  'common.expirationDate': 'Validity Period',
  'pages.remission.maxAmount': 'Max Reduction',
  'pages.remission.thisAmount': 'Current Reduction',
  'pages.collection.recharge.reduceReason': 'Reduce Reason',
  'common.cancel': 'Cancel',
  'common.downloadExcel': 'Download Excel',
  'pages.collectionCN.my.detail.lineAmount': 'LineAmount',
  'common.option.pass': 'Pass',
  'common.option.pass.confirm': 'Confirm Pass?',
  'common.option.reject': 'Reject',
  'common.option.reject.confirm': 'Confirm Reject?',
  'common.option.retry.confirm': 'Confirm Retry?',
  'common.option.success': 'Successful operated',
  'pages.finance.RefundManagement.approver': 'approver',
  'common.undo': 'Undo',
  'pages.marketing.marketingStrategyManage.addTrigger': 'Add',
  'pages.marketing.marketingStrategyManage.addAiTrigger': 'Add',
  'pages.marketing.couponUsedCount': 'No. Used Coupon User',
  'pages.marketing.customerUsedCount': 'No. Used Coupon',
  'pages.marketing.marketingStrategyManage.closetip':
    'closed all editing content will not save any info',
  'pages.marketing.category.btn.copy': 'Add backup methods',
  'pages.operation.marketing.config.editable.title': 'Title',
  'pages.operation.marketing.notification.parentNode.editable.sendTimeConfig': 'Send Time Config',
  'pages.operation.marketing.notification.parentNode.editable.effectiveDateType': 'Effective Date',
  'pages.operation.marketing.config.editable.messageTypeEnum': 'Message Type',
  'pages.operation.marketing.config.editable.channel': 'Channel',
  'pages.operation.marketing.config.editable.content': 'Content',
  'pages.operation.marketing.config.editable.backupChannel': 'Backup Channel',
  'pages.operation.marketing.template.name': 'Name',
  'pages.noticeAiEditTable.carrierRule': 'Carrier Rule(Not required)',
  'pages.operation.marketing.notification.parentNode.editable.content': 'Content',
  'common.copy': 'Copy',
  'pages.marketing.category.delete.tip': 'Delete current group related policies?',
  'pages.marketing.category.switchstatus.tip':
    'Policies for group currently activated cannot change status',
  'pages.operation.marketing.notification.record.messageType': 'Message Type',
  'pages.operation.marketing.notification.record.sendTime': 'Expect Send Time',
  'pages.review.riskControlCard.channel': 'Channel',
  'page.customer.contactRecord.sendTime': 'Send Time',
  'pages.operation.marketing.notification.record.sendTo': 'Send To',
  'common.notSaveTip': 'sure for run this? The edited content will not be saved',
  'pages.collection.specialDivision.switchManualStatusTip':
    'Are you sure you want to change the status? The status change will take effect in 5 minutes',
  'page.review.confirmTip1': 'Confirm to assign it to the selected auditor?',
  'pages.collection.changeLog.modifyStatus': 'modify status to',
  'pages.collection.changeLog.sync': 'Sync',
  'pages.collection.changeLog.toExcel': 'to Tencent Documentation',
};
